<template>
  <div class="modal">
    <div class="modal__header">
      <div class="modal__header__left">
        <span>Selecionar jornada </span>
        <br />
        <small>Escolha abaixo a jornada para ser editada</small>
      </div>
      <div class="modal__header__right">
        <z-input
          v-model="searchJourney"
          label="Pesquisar"
          placeholder="Pesquisar"
          prependIcon="$search_rounded"
          type="text"
          :hide-details="true"
        />
      </div>
    </div>
    <div v-if="isLoaded" class="modal__body">
      <div v-if="hasSomeInformation">
        <ul class="list mt-4">
          <li
            class="list__item"
            
            v-for="item in searchJourney ? searchedJourneysList : journeyList"
            :key="item.id"
            :class="{'active': selectedJourney?.id == item.id }"
            @click="setJourneyToEdit(item)"
          >
            <div class="list__item__text">
              <small> <strong>Nome:</strong></small>

              <span>{{ item.name }}</span>
            </div>

            <div class="list__item__text">
              <small> <strong>Criado em:</strong></small>

              <span>{{ formateDateTime(item.created) }}</span>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="!hasSomeInformation" class="modal__body__no-data">
        <strong>Não foi encontrado nenhuma audiência correspondente a busca.</strong>
      </div>
    </div>
    <div v-else class="modal__loading">
      <z-loading />
    </div>

    <div class="modal--actions">
      <z-btn text="Cancelar" @click="$emit('close')"></z-btn>

      <z-btn :disabled="!selectedJourney" primary @click="$emit('editJourney', selectedJourney)" text="Selecionar jornada"></z-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import ApiClient from "@/services/ApiClient";
export default {
  data() {
    return {
      isLoaded: true,
      filtered: [],
      journeyList: [],
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      http: new ApiClient(),
      audiencesList: [],
      selectedJourney: null,
      searchJourney: "",
    };
  },
  props: {
    nodeProp: { type: Object, default: null },
    audienceIdProp: { type: String, default: null },
  },
  async created() {
    await this.getJourneyList();
  },
  computed: {
    searchedJourneysList() {
      let ITEMS = [];
      if (this.searchJourney) {
        ITEMS = this.journeyList.filter((d) =>
          d.name.toLocaleLowerCase().includes(this.searchJourney.toLocaleLowerCase())
        );
      }

      return ITEMS;
    },
    hasSomeInformation() {
      let INFORMATION = this.journeyList.length > 0;
      return INFORMATION;
    },
  },

  watch: {
    searchJourney(nv) {
      this.filtered = this.audiencesList.filter((d) =>
        d.name.toLowerCase().includes(nv.toLowerCase())
      );
    },
  },
  methods: {
    setJourneyToEdit(journey){
        this.selectedJourney = journey
    },
    formateDateTime(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    formateDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async getJourneyList() {
      try {
        this.isLoaded = false;
        const req = await this.http.get(`journey/list`);
        this.journeyList = req;
        this.isLoaded = true;
      } catch (error) {
        this.$toast.error("Erro ao listar jornadas");
        this.isLoaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: #ffffff;
  border-radius: 15px;
  color: $z-black-1;
  padding: $z-s-1;
  min-height: 89.5vh;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__left {
      span {
        font-weight: 700;
        font-size: 1.1rem;
        line-height: 15px;
      }
    }
    &__right {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__body {
    flex: 1 1 100%;
    height: 100%;
    margin: 0.5rem 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: $z-s-1 $z-s-3;

    & > div {
      flex: 1 1 400px;
      .title {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0.5rem;
        padding: 0.5rem;

        figure {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        span {
          font-weight: 300;
          font-size: 20px;
          line-height: 23px;
        }
      }

      .list {
        padding: 0.5rem;
        max-height: 62vh;
        height: 100%;
        overflow-y: auto;
        @include trackScrollBar;
        &__item {
          list-style: none;
          cursor: pointer;
          padding: 0.8rem;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: 0.2s ease-in-out;

          &__text {
            display: flex;
            flex-direction: column;
          }

          &:not(:last-child) {
            margin-bottom: $z-s-2;
          }

          &:hover {
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.55);
          }
        }
        .active {
          background: #0273c3;
          color: white;
        }
      }
    }

    &__no-data {
      height: 60vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__loading {
    flex: 1 1 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
}
</style>
